<template>
  <div class="w-full flex flex-col bg-gray-50 h-full relative">
    <div class="overflow-y-auto pb-20 px-4 bg-gray-50">
      <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b bg-white border-gray-200">
        <div class="h-16 bg-white flex flex-row justify-between" id="takeover-toolbar-menu">
          <div class="bg-white w-full pt-4 h-16">
            <div class="absolute left flex flex-row ml-4">
              <button type="button" @click="toggleCheck" class="inline-flex items-center mr-3 px-4 py-1 text-sm leading-5 font-medium rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <div v-if="checkFlag"><span><span class="far fa-check-square"></span></span></div>
                <div v-else><span><span class="far fa-square"></span></span></div>
              </button>
              <combo-button :title="columnView" v-on:select-item="selectView" :items="views.map(v => { return {item: v, name: v.title, key: v.id, icon: null, counter: null, separator: false}})" />
            </div>
            <div class="absolute right-0 flex flex-row mr-8">
              <!--                            <button type="button" class="inline-flex items-center px-4 py-1 border border-transparent text-sm leading-5 font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-50 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-200 transition ease-in-out duration-75" onclick="javascript:applicationSideDetail.openToggle();">-->
              <!--                              <i class="far fa-plus-circle"></i>  &nbsp; &nbsp; Add trade in-->
              <!--                            </button>-->
              <combo-button :title="sortItem === null ? 'Sort' : sortItem.name" :right="true" :items="selectColumns()" v-on:select-item="selectSort" class="ml-2" />
              <button type="button" @click="sortFlag=!sortFlag" class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 font-medium rounded border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                <div v-if="sortFlag"><span><span class="fas fa-sort-amount-down"></span></span></div>
                <div v-else><span><span class="fas fa-sort-amount-up"></span></span></div>
              </button>
            </div>
          </div>
        </div>
        <table class="min-w-full">
          <thead>
          <tr>
            <th class="px-2 pl-4 py-4 border-b border-gray-200 bg-white text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><input type="checkbox" v-model="selectAll" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"></th>
            <th v-for="col in columns" v-bind:key="'col-' + col.id" :style="'min-width: ' + col.width + 'px;'" class="px-4 py-4 border-b border-gray-200 bg-white text-left text-xs leading-4 font-bold text-gray-500 uppercase tracking-wider">{{ col.title}}</th>
          </tr>
          </thead>
          <tbody v-if="items.length>0">
          <tr v-for="(item, index) in items" v-bind:key="'row-' + item.id" @click="edit(item)" :class="['hover:bg-blue-500 hover:text-white cursor-pointer', selectedId === item.id ? 'bg-blue-100' :  (selected.includes(item.id) ? 'bg-blue-50' : (index % 2 === 0 ? 'bg-white': 'bg-gray-50')),]">
            <td class="px-2 pl-4 py-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
              <input type="checkbox" :value="item.id" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" v-model="selected">
            </td>
            <td v-for="col in columns" v-bind:key="'row-' + item.id + '-col-' + col.id"><component :is="col.component" :column="col.id" :item="item" v-on:select-item="select" /></td>
          </tr>
          </tbody>
          <tbody v-if="items.length===0">
          <tr>
            <td :colspan="Object.keys(columns).length + 2">
              <div v-if="loading" class="flex flex-row justify-center items-center p-2 font-bold text-2xl"><span><span class="fas fa-circle-notch fa-spin"></span></span><span class="ml-4">Loading</span></div>
              <div v-else class="flex flex-row justify-center items-center p-2 font-bold text-2xl">No records found</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="absolute px-4 py-1 w-full h-13 static bottom-0 bg-gray-50 text-gray-800">
      Footer
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import comboButton from '@/components/combo-button'
import baseColumn from '../../../master/views/columns/base-column'
import tradeinColumnCustomer from '../columns/tradein-column-customer'
import fleetColumnKm from '../../../sales/views/columns/fleet-column-km'
import baseColumnId from '../../../master/views/columns/base-column-id'

export default {
  name: "Main",
  props: {},
  components: {
    'combo-button': comboButton,
    'base-column': baseColumn,
    'tradein-column-customer': tradeinColumnCustomer,
    'fleet-column-km': fleetColumnKm,
    'base-column-id': baseColumnId,
  },
  data() {
    const defaultColumns = {
      id: {width: 100, id: 'id', title: 'Id', component: 'base-column-id'},
      customer: {width: 200, id: 'Customer', title: 'Customer', component: 'tradein-column-customer'},
      km: {width: 100, id: 'km', title: 'Km', component: 'fleet-column-km'},
    };
    return {
      loading: true,
      columns: defaultColumns,
      columnView: 'General',
      selectedId: null,
      views: [{
        id: 'general',
        title: 'General',
        columns: defaultColumns
      }, {
        id: 'small',
        title: 'Small',
        columns:  defaultColumns
      }],
      checkFlag: false,
      sortFlag: false,
      sortItem: null,
      selectAll: false,
      selected: [],
      items: []
    }
  },
  methods: {
    edit(item) {
      if (this.checkFlag) {
        if (this.selected.filter(s => s === item.id).length > 0) {
          this.selected = this.selected.filter(s => s !== item.id)
        } else {
          this.selected.push(item.id);
        }
        return
      }
      this.selectedId = item.id;
      this.$store.commit("seller/openItem", item.id);
    },
    select(item) {
      this.$emit('select-item', item)
    },
    selectSort(item) {
      this.sortItem = item;
    },
    selectColumns() {
      return Object.keys(this.columns).map(c => {
        return {
          item: c,
          name: this.columns[c].title,
          key: this.columns[c].id,
          counter: null,
          icon: null,
          separator: false
        }
      });
    },
    toggleCheck() {
      this.checkFlag = !this.checkFlag;
    },
    selectView(view) {
      this.columns = view.item.columns;
      this.columnView = view.item.title;
    }

  },
  watch: {
    selectAll: function (val) {
      if (val) {
        this.selected = this.items.map(i => i.id);
      } else {
        this.selected = [];
      }
    }
  },
  created() {
    this.loading = true;
    const url = "/manager/seller/leadListData/1/";
    const params = {
      errorFlag: 0,
      plus: 0,
      part: 'waiting',
      searchTerm: '',
      _search: false,
      rows: 50,
      page: 1,
      sidx: '',
      sord: 'asc'
    }
    axios.get(url, {params: params}).then(response => {
      this.page = response.data.page
      this.totalPages = response.data.total
      this.items = response.data.rows
      this.loading = false;
    });
  }
}
</script>

<style scoped>

</style>



